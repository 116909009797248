<template>
  <div>
    <AppealStageItem
      v-for="(stage, index) in stages"
      :key="stage.id"
      :active="stage.active"
      class="mb-3"
    >
      <template #icon>
        <GearLoadingIcon v-if="stage.load" />
        <CheckMarkIcon v-else />
      </template>
      <template #title>
        Этап {{ index + 1 }}
      </template>
      <template
        v-if="stage.description"
        #description
      >
        {{ stage.description }}
      </template>
    </AppealStageItem>
  </div>
</template>

<script>
import AppealStageItem from '@/components/domains/appeals/AppealEditorModalNew/AppealsStages/AppealStageItem';
import CheckMarkIcon from '@/assets/images/check_mark.svg';
import GearLoadingIcon from '@/assets/images/gear_loading.svg';

export default {
  name: 'AppealStages',
  components: {
    AppealStageItem,
    CheckMarkIcon,
    GearLoadingIcon,
  },

  props: {
    stages: {
      type: Array,
      default: () => ([]),
    },
    stagesActiveStatus: {
      type: Object,
      default: () => ({}),
    },
    stageOneActive: {
      type: Boolean,
      default: false,
    },
    stageTwooActive: {
      type: Boolean,
      default: false,
    },
    stageThreeActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
