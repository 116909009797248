<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Фамилия"
        >
          <b-form-input
            :value="person.lastName"
            placeholder="Введите фамилию"
            disabled
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Имя"
        >
          <b-form-input
            :value="person.firstName"
            disabled
            placeholder="Введите имя"
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Отчество"
        >
          <b-form-input
            :value="person.middleName"
            disabled
            placeholder="Введите отчество"
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Номер телефона"
        >
          <b-form-input
            :value="person.phoneNumber"
            disabled
            placeholder="Номер телефона"
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Дата рождения"
        >
          <b-form-input
            :value="birthDate"
            type="date"
            disabled
            placeholder="Дата рождения"
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Возраст"
        >
          <b-form-input
            :value="age"
            disabled
            placeholder="Возраст"
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { formatDistanceToNowStrict, parseISO } from '@evd3v/date-fns';
import { dateWithoutTime } from '@/helpers/utils';
// import ruLocale from '@evd3v/date-fns/locale/ru';

export default {
  name: 'AppealPatientInfo',

  model: {
    prop: 'form',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    person: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    birthDate() {
      return dateWithoutTime(this.person.birthDate);
    },
    age() {
      if (!this.person || this.person.birthDate === null) return null;
      const date = new Date(this.person.birthDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateNow = new Date();
      const dayNow = dateNow.getDate();
      const monthNow = dateNow.getMonth() + 1;
      const yearNow = dateNow.getFullYear();
      let age = yearNow - year;

      if ((monthNow < month) || (monthNow === month && dayNow < day)) {
        age -= 1;
      }

      return age;
      // return formatDistanceToNowStrict(parseISO(this.person.birthDate), {
      //   locale: ruLocale,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
