<template>
  <div>
    <b-form-group
      label="Категория обращения"
      :invalid-feedback="errorsValidation.categoryId[0]"
    >
      <b-form-select
        v-model="v.form.categoryId.$model"
        :options="categories"
        :disabled="disabledFields.category"
        :state="v.$anyError ? !v.form.categoryId.$error : null"
        text-field="name"
        value-field="id"
        size="sm"
      >
        <template #first>
          <b-form-select-option
            :value="null"
          >
            Выберите категорию обращения
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <!-- categoryAnotherText -->
    <!-- Если выбрано "Другое" -->
    <b-form-group
      v-if="categoryAnotherShow"
      label="Другая категория обращения"
      :invalid-feedback="errorsValidation.categoryAnotherText[0]"
    >
      <b-input
        v-model="v.form.categoryAnotherText.$model"
        size="sm"
        :disabled="disabledFields.categoryAnotherText"
        :state="v.$anyError ? !v.form.categoryAnotherText.$error : null"
        placeholder="Введите текст"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'AppealCategory',

  model: {
    prop: 'v',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    v: {
      type: Object,
      default: () => ({}),
    },
    errorsValidation: {
      type: Object,
      default: () => ({}),
    },
    categories: {
      type: Array,
      default: () => ([]),
    },
    disabledFields: {
      type: Object,
      default: () => ({}),
    },
    categoryAnotherShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
