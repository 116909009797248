<template>
  <div>
    <b-form-group
      :invalid-feedback="errorsValidation.typeNewId[0]"
      label="Тип обращения"
    >
      <b-form-select
        v-model="v.form.typeNewId.$model"
        :options="types"
        :disabled="disabledFields.typeNewId"
        :state="v.$anyError ? !v.form.typeNewId.$error : null"
        text-field="name"
        value-field="id"
        size="sm"
      >
        <template #first>
          <b-form-select-option
            :value="null"
          >
            Выберите тип обращения
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <!-- categoryAnotherText -->
    <!-- Если выбрано "Другое" -->
    <b-form-group
      v-if="typeAnotherTextShow"
      label="Другой тип обращения"
    >
      <b-input
        v-model="v.form.typeAnotherText.$model"
        size="sm"
        :disabled="disabledFields.typeAnotherText"
        :state="v.$anyError ? !v.form.typeAnotherText.$error : null"
        placeholder="Введите текст"
      />
    </b-form-group>

    <!-- <b-form-group
      v-if="show.subtype"
      label="Подтип обращения"
    >
      <b-form-select
        :options="typeSubtypes"
        text-field="name"
        value-field="id"
        size="sm"
      >
        <template #first>
          <b-form-select-option
            :value="null"
            disabled
          >
            Выберите подтип обращения
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group> -->
  </div>
</template>

<script>
export default {
  name: 'AppealType',

  model: {
    prop: 'v',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    v: {
      type: Object,
      default: () => ({}),
    },
    types: {
      type: Array,
      default: () => ([]),
    },
    typeSubtypes: {
      type: Array,
      default: () => ([]),
    },
    errorsValidation: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Object,
      default: () => ({}),
    },
    typeAnotherTextShow: {
      type: Boolean,
      deafault: false,
    },
    disabledFields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
