<template>
  <div
    :class="[
      'appeal-stage-item',
      {'appeal-stage-item_active': active},
    ]"
  >
    <div
      v-if="$slots.icon"
      class="appeal-stage-item__icon-box"
    >
      <slot name="icon" />
    </div>

    <div
      v-if="$slots.title"
      class="appeal-stage-item__title"
    >
      <slot name="title" />
    </div>
    <div
      v-if="$slots.description"
      class="appeal-stage-item__description"
    >
      <slot name="description" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppealStageItem',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.appeal-stage-item {
  width: 154px;
  height: 124px;
  border-radius: 16px;
  padding: 16px;
  background-color: #F8F9FE;

  &_active {
    background-color: $blue;
    color: #fff;
  }

  &__icon-box {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.appeal-stage-item_active .appeal-stage-item {
  &__icon-box {
    border: 1px solid #fff;
  }
}

.appeal-stage-item ::v-deep svg {
  path {
    fill: #6E88F3;
    stroke: #6E88F3;
  }
}

.appeal-stage-item_active ::v-deep svg{
  path {
    fill: #fff;
    stroke: #fff;
  }
}
</style>
