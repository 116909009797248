<template>
  <b-row>
    <b-col
      v-for="policy in policies"
      :key="policy.id"
      md="6"
      class="mb-2"
    >
      <AppealPolicyItem
        :policy="policy"
        :person="person"
        :active="policy.id === selectedPolicy.id"
        :disabled="disabled"
        :show-create-direction="false"
        show-select-button
        :error="error"
        class="h-100"
        @select-policy="$emit('select-policy', policy.id)"
      />
    </b-col>
  </b-row>
</template>

<script>
import AppealPolicyItem from '@/components/domains/appeals/AppealEditorModalNew/AppealPoliciesList/AppealPolicyItem';

export default {
  name: 'AppealPoliciesList',
  components: {
    AppealPolicyItem,
  },

  props: {
    error: {
      type: Boolean,
      default: false,
    },
    policies: {
      type: Array,
      default: () => ({}),
    },
    person: {
      type: Object,
      default: () => ({}),
    },
    selectedPolicy: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
</style>
