<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Дата чекапа"
          style="width: 200px;"
          :invalid-feedback="errorsValidation.checkupDate[0]"
          :state="v.$anyError ? !v.form.checkupDate.$error : null"
        >
          <base-date-picker
            v-model="v.form.checkupDate.$model"
            :disabled="disabledFields.checkupDate"
            :error="v.form.checkupDate.$error"
            :position-fixed="true"
          />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group
          label="Профиль для чекапа"
          :invalid-feedback="errorsValidation.checkupProfileId[0]"
        >
          <b-form-select
            v-model="v.form.checkupProfileId.$model"
            :options="checkupProfilesList"
            :disabled="disabledFields.checkupProfileId"
            :state="v.$anyError ? !v.form.checkupProfileId.$error : null"
            text-field="title"
            value-field="id"
            size="sm"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите профиль
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      v-if="showCheckupAnotherText"
      label="Другой профиль для чекапа"
      :invalid-feedback="errorsValidation.checkupAnotherText[0]"
    >
      <b-input
        v-model="v.form.checkupAnotherText.$model"
        :disabled="disabledFields.checkupAnotherText"
        :state="v.$anyError ? !v.form.checkupAnotherText.$error : null"
        size="sm"
      />
    </b-form-group>
  </div>
</template>

<script>
import { BaseDatePicker } from '@/components/base';

export default {
  name: 'AppealCheckout',
  components: {
    BaseDatePicker,
  },

  model: {
    prop: 'form',
    event: 'change',
  },
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    v: {
      type: Object,
      default: () => ({}),
    },
    disabledFields: {
      type: Object,
      default: () => ({}),
    },
    errorsValidation: {
      type: Object,
      default: () => ({}),
    },
    checkupProfilesList: {
      type: Array,
      default: () => ([]),
    },
    showCheckupAnotherText: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
